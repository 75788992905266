import api from './utilBackoffice';

const controller = 'ErrosProcessoDiario';
const url = process.env.VUE_APP_ROOT_API + controller;

export default Object.assign({}, api.GetCrud(controller, null), {
  ListarSaldoDiario: dto =>
    api.Axios.GetParams(url + '/ListarSaldoDiario', dto),
  ListarErros: dto => api.Axios.GetParams(url + '/ListarErros', dto)
});
